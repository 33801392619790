@tailwind base;
@layer base {
    h1 {
        @apply text-2xl font-bold leading-tight text-brown pb-4;
        @apply md:text-6xl;
    }

    h2 {
        @apply text-xl font-bold leading-tight text-brown pb-4;
        @apply md:text-4xl;
    }

    h3 {
        @apply text-lg font-bold leading-tight text-brown pb-4;
        @apply md:text-2xl;
    }

    p {
        @apply pb-4;
    }

    .vms-color-complete {
        color: rgb(22, 163, 74);
    }

    .vms-color-incomplete {
        color: rgb(220, 38, 38);
    }

    a {
        color: blue
    }
}

@tailwind components;
@tailwind utilities;

@layer components {
    .btn-primary {
        @apply px-4 py-2 font-medium text-white rounded-lg shadow-md bg-brown hover:bg-lightBrown focus:outline-none focus:ring-2;
    }

    .card {
        @apply p-4 bg-white shadow-xl md:p-8 rounded-xl;
    }
}
